import ExpenseItem from "./ExpenseItem";
import Card from '../UI/Card'
import './Expenses.css';

function Expenses(props) {
    return(
        <Card className="expenses">

        {props.items.map((expense) => (
            <ExpenseItem 
                key={expense.id}
                title={expense.title} 
                amount={expense.amount} 
                date={expense.date}
            />
        ))}
        </Card>
    )
}

export default Expenses;